@import url('https://fonts.googleapis.com/css2?family=Baskervville:ital@0;1&family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

* {
  background-color: #F9F9F9;
  cursor: default;
}

::selection {
  background: #f4cce1;
}

.main {
  text-align: center;
  text-align: left;
  padding-top: 5em;
  padding-left: 2em;
  padding-right: 2em;
  text-align: center;
}

.heading {
  font-family: 'Baskervville', serif;
  font-style: normal;
  font-weight: normal;
  font-size: 50px;
  line-height: 93px;
  color: #0404D9;
  text-align: center;
}

.subhdr {
  font-family: 'Roboto Mono', monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #fa50ad;
  padding-top: 1em;
}


.links {
  font-family: 'Roboto Mono', monospace;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
  color: #2727E6;
  letter-spacing: 0.2em;
  padding-top: 1.5em;
}

.linksText:hover {
  background-color: #fded57;
  /* background-color: #ffdaed; */
  cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'  width='50' height='50' viewport='0 0 100 100' style='fill:black;font-size:24px;'><text y='50%'>🔍</text></svg>") 16 0, auto;
}

#aboutMe {
  padding-top: 1em;
  padding-left: 17%;
  padding-right: 15%;
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #0404D9;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  /* Align height */
}

.highlight {
  background-color: #fded57;
}

.highlight:hover {
  cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'  width='40' height='48' viewport='0 0 100 100' style='fill:black;font-size:24px;'><text y='50%'>🔍</text></svg>") 16 0, auto;
}

#categories {
  padding-bottom: 2.5em;
  padding-top: 0.5em;
}

a {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
  color: #0404D9;
}

.pink {
  color: #fa50ad;
}

#top,
#bottom,
#left,
#right {
  background: #0404D9;
  position: fixed;
}

#left,
#right {
  top: 0;
  bottom: 0;
  width: 2px;
}

#left {
  left: 0;
}

#right {
  right: 0;
}

#top,
#bottom {
  left: 0;
  right: 0;
  height: 2px;
}

#top {
  top: 0;
}

#bottom {
  bottom: 0;
}

img {
  border-radius: 5%;
  border: 1.5px solid #0404D9;
  padding: 10px;
  height: 40vh;
  min-height: 350px;
  float: center;
  margin-top: 10px;
}

#descrip {
  padding-left: 2em;
}

@media only screen and (max-width: 900px) {
  img {
    height: 35vh;
    margin: auto;
    margin-top: 1em;
  }

  #aboutMe {
    padding-top: 1em;
    font-family: Roboto Mono;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    color: #0404D9;
    text-align: left;
    display: contents;
  }

  #descrip {
    padding-top: 2em;
  }
}

.bold {
  text-decoration: underline;
}

body {
  margin: 20px;
}